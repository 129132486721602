.AddButton {
  /* margin-left: px; */
  background-color: green;
}
.ToggleClassBtnRadio {
  width: 150px;
}

.Qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 595%;
  margin: 0 auto;
  /* background-color: #f7f7f7; */
}

.qrimg {
  width: 100px;
  height: 100px;
  border: 1px solid #de5c2e;
  /* margin-left: 40px; */
}

@media (max-width: 425px) {
  .Qrcode {
    width: 388%;
  }
}
@media print {
  ::-webkit-scrollbar {
    display: none;
}
  .ReceptContainer {
      border: groove #000 !important;
      border-width: 1px !important;
  }
  .table {
    border: groove #000 !important;
    border-width: 1px !important;
}
  th, td {
      border: groove #000 !important;
      border-width: 1px !important;
  }
}
